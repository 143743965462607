import React, {useState, useEffect} from 'react';
import logo from './logo.jpg';
import selectorLogo from './selectorlogo.jpg';
import './App.css';
import HomeData from './data/home_data.json';
import LabelData from './data/label_data.json';
import ManagementData from './data/management_data.json';
import SelectorData from './data/selector_data.json';
import ArtistCard from './components/ArtistCard';
import fb_logo from './images/facebook.svg';
import apple_logo from './images/apple.svg';
import ig_logo from './images/instagram.svg';
import spotify_logo from './images/spotify.svg';
import tiktok_logo from './images/tiktok.png';
import twitter_logo from './images/twitter.svg';
import yt_logo from './images/yt.png'
import sc_logo from './images/soundcloud.svg';
import { Link } from 'react-scroll';

const header_img = "https://lh3.googleusercontent.com/d6l6qz7mlKAukKLFf3PfflTDCWb93Usy4BlacuPgN5nHw49db1sB317uFvNDMeUfANu1uf-6aVFnA1izibl3acYkPtjsqXXBNP2WgoVo4a4JmYlbIa7mdpkMeDRFt--QJ4b1u52kIQ=w2400";

function App() {
  const [activePage, setActivePage] = useState('home');

  useEffect(() => {
    console.log(activePage)
  }, [])

  return (
    <div className="App">
      <div className="header">
        <div className="header-banner">
          <div className="header-logo-blurb-wrapper">
            <Link id="header-link" style={{margin: 'auto', width: 'fit-content'}} activeClass="active" to="header" spy={true} smooth={true} offset={0} duration={250}>
              <div className="header-logo-wrapper">
                <img className="header-logo" src={logo} onClick={() => setActivePage("home")}/>
              </div>
            </Link>
          </div>
          <p className="header-blurb-text">A JOINT VENTURE WITH SONY MUSIC ENTERTAINMENT.</p>
          <div className='navbar-wrapper'>
            {activePage != "home" && 
            <div className="navbar-link-wrapper" onClick={() => setActivePage("home")}>
                <p className={activePage === "home" ? "active-navbar-link" : "navbar-link"}>ALL</p>
            </div>}
            <Link style={{display: 'flex'}} activeClass="active" to="body" spy={true} smooth={true} offset={-100} duration={250}>
              <div className="navbar-link-wrapper" onClick={() => setActivePage("label")}>
                  <p className={activePage === "label" ? "active-navbar-link" : "navbar-link"}>LABEL</p>
              </div>
            </Link>
            <Link style={{display: 'flex'}} activeClass="active" to="body" spy={true} smooth={true} offset={-100} duration={250}>
            <div className="navbar-link-wrapper" onClick={() => setActivePage("management")}>
                <p className={activePage === "management" ? "active-navbar-link" : "navbar-link"}>MANAGEMENT</p>
            </div>
            </Link>
            <Link style={{display: 'flex'}} activeClass="active" to="body" spy={true} smooth={true} offset={-100} duration={250}>
            <div style={{marginRight: '2vw'}} className="navbar-link-wrapper" onClick={() => setActivePage("selector")}>
                <p className={activePage === "selector" ? "active-navbar-link" : "navbar-link"}>PUBLISHING</p>
            </div>
            </Link>
        </div>
        </div>
        <div id="body" className="artists-wrapper">
        <div className="artists-mobile-col">
            {/* Loop through artists info we want, depending on active page  */}
            {
            activePage === "home" ? 
            HomeData.mobile.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "label" ? 
            LabelData.mobile.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "management" ? 
            ManagementData.mobile.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "selector" ? 
            SelectorData.mobile.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            <React.Fragment />
          }
          </div>
          <div className="artists-left-col">
            {/* Loop through artists info we want in left, depending on active page  */}
            {
            activePage === "home" ? 
            HomeData.leftCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "label" ? 
            LabelData.leftCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "management" ? 
            ManagementData.leftCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "selector" ? 
            SelectorData.leftCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            <React.Fragment />
          }
          </div>
          <div className="artists-right-col">
            {/* Loop through artists info we want in right, depending on active page */}
            {
            activePage === "home" ? 
            HomeData.rightCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "label" ? 
            LabelData.rightCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            : 
            activePage === "management" ? 
            ManagementData.rightCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            })
            :
            activePage === "selector" ?
            SelectorData.rightCol.map((artist) => {
              return <ArtistCard artist={artist}/> 
            }) : 
            <React.Fragment />
            }
          </div>
        </div>
      </div>
      <div className="footer">
            <div className="footer-mid-mobile">
              <div className="footer-mid-btn">
                <p className="footer-mid-btn-text">CONTACT</p>
              </div>
            </div>
            <div className="footer-left">
              <div className="footer-disruptor-logo-wrapper">
                <img className="footer-disruptor-logo" src={logo}/>
              </div>
              <div className="footer-disruptor-btns">
                <a href="https://www.instagram.com/disruptorrecs/" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={ig_logo}/>
                </a>
                <a href="https://www.facebook.com/disruptorrecs/" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={fb_logo}/>
                </a>
                <a href="https://twitter.com/disruptorrecs/" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={twitter_logo}/>
                </a>
                <a href="https://www.tiktok.com/@disruptorrecs?lang=en" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={tiktok_logo}/>
                </a>
              </div>
            </div>
            <div className="footer-mid">
              <div className="footer-mid-btn">
                <a href="mailto:info@disruptorrecords.com" className="footer-mid-btn-text">CONTACT</a>
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-disruptor-logo-wrapper">
                <img style={{minWidth: "20px", minHeight: '20px'}} id="selector-logo" className="footer-disruptor-logo" src={selectorLogo}/>
              </div>
              <div className="footer-disruptor-btns">
                <a href="https://www.instagram.com/selectorsongs/" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={ig_logo}/>
                </a>
                <a href="https://www.facebook.com/selectorsongs/" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={fb_logo}/>
                </a>
                <a href="https://twitter.com/selectorsongs/" className="footer-btn">
                  <img style={{minWidth: "20px", minHeight: '20px', filter: 'brightness(0) invert(1)'}} className="social-logo" src={twitter_logo}/>
                </a>
              </div>
            </div>
      </div>
    </div>
  );
}

export default App;
