import React, {useState, useEffect} from 'react';
import fb_logo from '../images/facebook.svg';
import apple_logo from '../images/apple.svg';
import ig_logo from '../images/instagram.svg';
import spotify_logo from '../images/spotify.svg';
import tiktok_logo from '../images/tiktok.png';
import twitter_logo from '../images/twitter.svg';
import yt_logo from '../images/yt.png'


function ArtistCard(props) {
    return(
        <div className="artist-card-wrapper">
            <div className="artist-card-top">
                {/* <img src=props.artist.pic /> */}
                <img className="temp-img" src={props.artist.pic} />
            </div>
            <div className="artist-card-bottom">   
                <p className="artist-card-name">{props.artist.name}</p>
                <div className="artist-card-btn-group">
                    {props.artist.insta_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.insta_link}> {/*will like to instagram page */}
                            <img className="social-logo" src={ig_logo}/>    
                        </a>
                    </div>}
                    {props.artist.tiktok_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.tiktok_link}> {/*will like to tiktok page */}
                            <img className="social-logo" src={tiktok_logo}/>
                        </a>
                    </div>}
                    {props.artist.fb_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.fb_link}> {/*will like to fb page */}
                            <img className="social-logo" src={fb_logo}/>
                        </a>
                    </div>}
                    {props.artist.twitter_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.twitter_link}> {/*will like to twitter page */}
                            <img className="social-logo" src={twitter_logo}/>
                        </a>
                    </div>}
                    {props.artist.spotify_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.spotify_link}> {/*will like to spotify page */}
                            <img className="social-logo" src={spotify_logo}/>
                        </a>
                    </div>}
                    {props.artist.apple_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.apple_link}> {/*will like to apple music page */}
                            <img className="social-logo" src={apple_logo}/>
                        </a>
                    </div>}
                    {props.artist.youtube_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.youtube_link}> {/*will like to youtube page */}
                            <img style={{height: '1.5vw', maxHeight: '10px', margin: 'auto',   filter: 'brightness(0) invert(0)'}} className="social-logo" src={yt_logo}/>
                        </a>
                    </div>}
                    {props.artist.website_link !== "" && <div className="artist-card-btn">
                        <a className="artist-card-btn-link" href={props.artist.website_link}> {/*will like to tiktok page */}
                            <p className="artist-website-btn" style={{color: 'black', textDecoration: 'underline'}}>www</p>
                        </a>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ArtistCard;